<template>
	<div class="layout-profile">
		<div>
			<img :src="url.foto+$store.state.foto"/>
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{$store.state.usuario}}</span>
			<i class="bi bi-pen"></i>
		</button><br>
		<span style="color: white;">{{$t('Cliente')+' # '}}{{($store.state.id).toString().padStart(7,'0')}}</span>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><InputSwitch v-model="light" @change="changelayoutColorMode($event)"/><br>
					<span class="p-link layout-profile-link"><i class="bi bi-lightbulb"></i></span></li>
                <li><button class="p-link" @click="Profile()"><i class="pi pi-fw pi-user"></i><span>{{$t('Mi Perfil')}}</span></button></li>
				<!--<li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>{{$t('Notificaciones')}}</span><span class="menuitem-badge">2</span></button></li>-->
                <li><button class="p-link" @click="Logout()"><i class="pi pi-fw pi-power-off"></i><span>{{$t('Salir')}}</span></button></li>
            </ul>
        </transition>
	</div>
</template>

<script>
import url from "./service/_URL";

export default {
	props: {
		layoutColorMode: {
			type: String,
			default: null,
		},
		user: {
			type: String,
			default: null,
		}
	},
	data() {
		return {
			expanded: false,
			d_user: this.user,
			light: false,
			url: null,
		}
	},
	created() {
		this.url = url;
	},
	methods: {
		onClick(event){
			this.expanded = !this.expanded;
			event.preventDefault();
		},
		changeLayoutColor(event, layoutColor) {
			this.$emit('layout-color-change', layoutColor);
			event.preventDefault();
		},
		changelayoutColorMode($event) {
			if (this.light){
				this.changeLayoutColor($event, 'light');
			} else {
				this.changeLayoutColor($event, 'dark');
			}
		},
		onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		Logout() {
			this.onMenuToggle();
			this.$store.commit('Logout');
			this.$router.push({ path: '/' });
        },
		Profile() {
			this.$router.push({ path: '/profile' });
        }
	},
	watch: {
		layoutColorMode(newValue) {
			this.d_layoutColorMode = newValue;
		}
	},
	computed: {
		rippleActive() {
			return this.$primevue.ripple;
		}
	}
}
</script>

<style scoped>

</style>