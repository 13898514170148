import url from "./_URL";
import axios from "axios";
import store from "../store";
import router from '../router.js';


const API = function (parameters){
  const http = axios.post(url.api, parameters);
  return http; 
}


class APIs {
  constructor(modulo=null,funcion=null,error=false) {
    this.modulo =   modulo;
    this.funcion =  funcion;
    this.error =  error;
    this.token =    localStorage.getItem('Token');
  }
  Estatus() {
    store.state.loading = true;
    return API({
        modulo:  this.modulo
    }).then(res => {
        store.state.loading = false;
        //store.state.error = res;
        return res.data;
    }).catch(error => { 
        this.ErrorConexion(error);
    });
  }
  Ini() {
    store.state.loading = true;
    return API({
          modulo:     this.modulo,
          funcion:    this.funcion,
          token:      this.token,
      }).then(res => {
          store.state.loading = false;
          //if(this.error){store.state.error = res;}
          return res.data;
      }).catch(error => {  
          this.ErrorConexion(error);
      });
  }
  Procesar(cas,array) {
      store.state.loading = true;
      return API({
          modulo:     this.modulo,
          funcion:    this.funcion,
          token:      this.token,
          case:       cas,
          array:      array
      }).then(res => {
          store.state.loading = false;
          if(this.error){store.state.error = res;}
          return res.data;
      }).catch(error => { 
          this.ErrorConexion(error);
      });
  }

  ErrorConexion(error) {
    console.log(error);
    store.state.loading = false;
    router.push({ path: '/' });  
  }

  fstring(fech){
      if(fech){
          const [year, month, day] = fech.split('-');
          return day+'/'+month+'/'+year;    
      } else {
          return null;
      }
  }

  fdate(dato){
      if(dato){
          const [day, month, year] = dato.split('/');
          return new Date(+year, +month - 1, +day);
      } else {
          return null;
      }
  }

}

export default APIs;