<template>
	<TabMenu :model="this.$store.state.menu[i18n.locale()].basico" class="styl"/>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		}
	}
</script>
