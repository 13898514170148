<template>
	<div id="llayout-menu-container" :class="containerClass">
		<a href="#" class="layout-config-button" id="layout-config-button" @click="toggleConfigurator">
			<i class="pi pi-spin pi-cog" style="fontSize: 2rem"></i>
		</a>
		<a href="#" class="layout-config-close" @click="hideConfigurator">
			<i class="pi pi-times"></i>
		</a>

		<div class="layout-config-content">
			<h5>{{$t('Menú Configuración')}}</h5>
			<Menu :model="this.$store.state.menu[i18n.locale()].admin" style="width: 18em;" @click="toggleConfigurator"/>
		</div>

	</div>
</template>

<script>
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
	
	props: {
		layoutMode: {
			type: String,
			default: null
		},
		layoutColorMode: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			active: false,
			d_layoutColorMode: this.layoutColorMode,
			menu: [],
			i18n: null,
		}
	},
    created() {
		this.i18n = useI18nPlugin();
	},
	watch: {
		$route() {
			if (this.active) {
				this.active = false;
				this.unbindOutsideClickListener();
			}
		},
		layoutColorMode(newValue) {
			this.d_layoutColorMode = newValue;
		}
	},
	outsideClickListener: null,
	methods: {
		toggleConfigurator(event) {
			this.active = !this.active;
			event.preventDefault();
			if (this.active)
				this.bindOutsideClickListener();
			else
				this.unbindOutsideClickListener();
		},
		hideConfigurator(event) {
			this.active = false;
			this.unbindOutsideClickListener();
			event.preventDefault();
		},
		changeLayoutColor(event, layoutColor) {
			this.$emit('layout-color-change', layoutColor);
			event.preventDefault();
		},
		bindOutsideClickListener() {
			if (!this.outsideClickListener) {
				this.outsideClickListener = (event) => {
					if (this.active && this.isOutsideClicked(event)) {
						this.active = false;
					}
				};
				document.addEventListener('click', this.outsideClickListener);
			}
		},
		unbindOutsideClickListener() {
			if (this.outsideClickListener) {
				document.removeEventListener('click', this.outsideClickListener);
				this.outsideClickListener = null;
			}
		},
		isOutsideClicked(event) {
			return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
		}
	},
	computed: {
		containerClass() {
			return ['layout-config', {'layout-config-active': this.active}];
		},
		rippleActive() {
			return this.$primevue.ripple;
		},
		inputStyle() {
			return this.$appState.inputStyle;
		}
	}
}
</script>
