let API = process.env.VUE_APP_API;

let URL = {
  doc: API,
  api: API,
  foto: API+"foto/",
  upload: API+"upload/",
  val: "https://sabeeinsurance.com/#/query/",
  tlf: '+59996905811',
  correo: process.env.VUE_APP_LOGIN,
  clave: process.env.VUE_APP_PASS
};

export default URL;